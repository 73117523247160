<template>
  <div
    class="container-fluid section-container"
    style=" width='1024'
        height='480' "
  >
    <div class="row">
      <div
        v-if="pratilipiList && pratilipiList.length >= 6"
        class="col-12 section-title-container"
        style="color: #ffff;"
      >
        <h1 class="section-title">
          {{ title }}
        </h1>
      </div>
    </div>
    <div
      v-if="pratilipiList && pratilipiList.length >= 6"
      class="pratilipi-list"
    >
      <slick
        v-if="showList"
        ref="slick"
        :options="slickOptions"
        class="slick-pratilipis"
      >
        <div
          v-for="(eachPratilipi, index) in pratilipiList"
          :key="eachPratilipi.id + '_' + index"
        >
          <PratilipiComponent
            v-if="eachPratilipi && eachPratilipi.attributes"
            :pratilipi-data="eachPratilipi.attributes"
            :type="eachPratilipi.type"
            :index="index"
            :position="position"
          />
        </div>
      </slick>
    </div>
  </div>
</template>

<script type="text/javascript">
import PratilipiComponent from "@/components/PratilipiSlim.vue";
import Slick from "vue-slick";
import inViewport from "vue-in-viewport-mixin";
import { mapGetters } from "vuex";
import mixins from "@/mixins";

export default {
  name: "PratilipiList",
  components: {
    PratilipiComponent,
    Slick,
  },
  mixins: [mixins, inViewport],
  props: {
    pratilipiList: {
      type: Array,
      required: true,
    },
    position: {
      type: Number,
    },
    screenName: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
    listPageUrl: {
      type: String,
    },
  },
  data() {
    return {
      slickOptions: {
        infinite: false,
        adaptiveHeight: false,
        slidesToScroll: 3,
        //variableWidth: true,
        draggable: true,
        edgeFriction: 0.3,
        slidesToShow: 5,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 3,
              infinite: true,
              draggable: true,
            },
          },
          {
            breakpoint: 641,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              initialSlide: 2,
              draggable: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 2,
              draggable: true,
            },
          },
        ],
        swipe: true,
        rows: 0,
        prevArrow:
          '<div class="back-pratilipi-arrow"><i  aria-hidden="true">&gt;</i></div>',
        nextArrow:
          '<div class="forward-pratilipi-arrow"><i  aria-hidden="true">&lt;</i></div>',
      },
      currentLocale: "",
      showList: true,
    };
  },
  watch: {
    "inViewport.now": function(visible) {
      if (visible) {
        if (this.screenName === "HOME") {
          /*this.triggerAnanlyticsEvent(
                        `VIEWED_COLLECTIONS_HOME`,
                        "CONTROL",
                        {
                            USER_ID: this.getUserDetails.userId,
                            PARENT_ID: this.listPageUrl,
                            POSITION: this.position,
                        }
                    );*/
        }
      }
    },
  },
  created() {
    {
      this.slickOptions.nextArrow =
        '<div class="forward-pratilipi-arrow" style="color: rgb(255, 255, 255);  "><i  aria-hidden="true"></i></div>';
      this.slickOptions.prevArrow =
        '<div class="back-pratilipi-arrow" style="color: rgb(255, 255, 255);"><i class="" aria-hidden="true"></i></div>';
      // this.slickOptions.rtl = true;
    }
    if (this.isMobile()) {
      this.slickOptions.slidesToScroll = 2;
      this.slickOptions.prevArrow = null;
      this.slickOptions.nextArrow = null;
    }
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
  },
};
</script>
<style lang="scss" scoped>
.container-fluid {
  width: 202;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
}
.section-container {
  .section-title-container {
    @media screen and (min-width: 768px) {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      margin-left: 8px;
      color: #ffffff;
    }
    .section-title {
      font-family: Helvetica;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fefefe;
      display: flex;
      justify-content: left;
      a {
        color: #ffffff;
        padding: 5px 0 5px 7px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      @media screen and (max-width: 576px) {
        font-size: 14px;
       
        width: calc(100% - 30px);
       margin-bottom: 4px;
      }
      &.partnership-title {
        @media screen and (max-width: 576px) {
          width: calc(100% - 160px);
          max-width: 60%;
        }
      }
    }

    .section-title-see-more {
      margin: 5px 0 0;
      padding: 0;
      font-size: 18px;
      text-align: right;
      line-height: 1.56;
      width: auto;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      a {
        color: #ffffff;
        padding: 10px 5px 5px 10px;
        display: block;
      }
      @media screen and (max-width: 576px) {
        font-size: 17px;
        margin-bottom: 0;
      }
      &.icon-only {
        width: 30px;
      }
    }
  }
}

.pratilipi-list {
  position: relative;
  padding: 2px 0px 16px 5px;
  a.view_more {
    position: relative;
    .view_more_card {
      width: 150px;
      border: 1px solid #e9e9e9;
      @media screen and (max-width: 768px) {
        width: 128px;
        height: 158px;
        margin: 6px 6px;
      }
      background: #fff;
      height: 180px;
      margin: 0px 10px;
      color: #036974;
      i {
        height: 187px;
        line-height: 179px;
        width: 100%;
        font-size: 40px;
        @media screen and (max-width: 768px) {
          height: 165px;
        }
      }
      .align-right-urdu {
        text-align: right !important;
      }
      span {
        display: block;
        font-size: 14px;
        text-align: left !important;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
<style lang="scss" scoped>
.overflow-none {
  overflow: initial !important;
}
.line-height-inc {
  max-height: 55px;
}
</style>
<style lang="scss">
@import "../../node_modules/slick-carousel/slick/slick.css";
.slick-slide {
  @media screen and (min-width: 768px) {
  width: 184.6px!important;
  height: 198px!important;
}
}
.slick-track {
  height: 173px;
  @media screen and (max-width: 768px) {
    height: 143px;
    margin-left: -14px;
  }
}
slick-slide, slick-current, slick-active{

}
.back-pratilipi-arrow,
.forward-pratilipi-arrow {
  
  position: absolute;
  top: 30%;
  z-index: 2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  transition: all 0.3s, visibility 0s;
  -ms-transition: all 0.3s, visibility 0s;
  -webkit-transition: all 0.3s, visibility 0s;
  -moz-transition: all 0.3s, visibility 0s;
  i {
    height: 40px;
    line-height: 40px;
    font-size: 32px;
  }
}
.back-pratilipi-arrow {
 background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
 left: -36px;
 height: 21px;
 width: 23px;
  @media screen and (max-width: 768px) {
    left: -25px;
  }
}
.forward-pratilipi-arrow {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  right: -0px;
  color: #ffffff;
  height: 21px;
  width: 23px;
  @media screen and (max-width: 768px) {
    right: -30px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",staticStyle:{"background-image":"linear-gradient(to bottom, #313c33, #161c17)"}},[_c('b-container',[_c('b-carousel',{attrs:{"controls":"","indicators":"","img-width":"1024","img-height":"480","interval":3000}},_vm._l((_vm.banners),function(each_banner,index){return _c('div',{key:index,staticClass:"banners"},[_c('router-link',{attrs:{"to":{
            name: 'home-series',
            params: {
              slug: each_banner.attributes.actionUrl.split(
                'comic-series/'
              )[1],
            },
          }},nativeOn:{"click":function($event){return _vm.trigerclick(each_banner)}}},[_c('b-carousel-slide',{staticClass:"image image-inside",attrs:{"img-src":_vm.getHighResolutionImage(
                each_banner.attributes.imageUrl +
                  '?width=240&enforceAspectRatio=true'
              )}})],1)],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
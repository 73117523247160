<template>
  <div class="pratilipi-wrap">
    <router-link 
      :to="{
        name: 'home-series',
        params: { slug: this.pratilipiData.slug?this.pratilipiData.slug:this.pratilipiData.seriesId },
      }"
      @click.native="triggerClickSeriesEvent(); ;"
    >
      <PratilipiImage
        :cover-image-url="
          pratilipiData.coverImageUrl + '&width=150&enforceAspectRatio=false'
        "
        :display-title="pratilipiData.title"
      />
     <div class="pratilipi-details">
      <div class="container" style="margin-left:-10px">
        <span class="rating-star-img"
          ><img class="star"
            alt=""
            src="https://comicassets.pratilipi.com/website/img/icon-star-gold.svg"
          />
        </span>

        <span class="title" style="color:#f5bc00">
          {{ pratilipiData.comicSocial.averageRating | round(1) }}
        </span>
      </div>

      <span class="seriestile" itemprop="name">{{ pratilipiData.title }}</span>
    </div>
 </router-link>
    <!-- <span
      v-if="pratilipiData.comicSocial.averageRating >= 1"
      itemprop="ratingValue"
      class="rating-tag"
    >
      <span class="rating-star-img"><img alt="" src="static/star.svg" /> </span>
      <span>
        {{ pratilipiData.comicSocial.averageRating | round(1) }}
      </span> -->
    <!-- </span> -->

    <!-- <div class="title">
      {{ pratilipiData.title }}

    </div> -->

  </div>
</template>

<script>
import PratilipiImage from "@/components/PratilipiImage";
import { mapGetters } from "vuex";
import mixins from "@/mixins";

export default {
  name: "Pratilipi",
  components: {
    PratilipiImage,
  },
  mixins: [mixins],
  props: {
    pratilipiData: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    position: {
      type: Number,
    },
  },
  created() {
    // console.log(this.pratilipiData)
  },
  methods:{
    triggerClickSeriesEvent() {
      //console.log(this.pratilipiData.seriesId)
      
      this.triggerAnanlyticsEvent(`CLICK_CONTENT_CARD`, "CONTROL", {
        UI_POSITION: this.position,
        SCREEN_NAME: "HomePage",
        SERIES_ID: this.pratilipiData.seriesId,
        SERIES_NAME: this.pratilipiData.title,
        language: "HINDI"
      });
    }, 
},
  
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: flex-start;
  justify-content: left;
}
img {
  max-width: 100%;
}
.rating-star-img {
  padding-top: 2px;
  flex-basis: 10%;
}
.title {
  width: 20px;
  height: 12px;
  margin: 3px 4px 5px 3px;
  font-family: NotoSansDevanagari;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  color: #f5bc00;
}
.seriestile {
  justify-content: flex-start;
  width: 100%;
  display: flex;
  height: 23px;
  font-family: NotoSansDevanagari;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fefefe;
  margin-left: 5px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 23px;
    font-family: NotoSansDevanagari;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fefefe;
    margin-left: 7px;
  }
}

.pratilipi-wrap {
  margin-right: 18.3px;
  margin-left: 18.3px;
  height: 198px;
  @media screen and (max-width: 768px) {
    margin-right: 7px !important;
    margin-left: 7px !important;
    height: 144px !important ;
    width: 114px !important;
  }

}
.star{
    @media screen and (max-width: 768px) {
    margin-left: 2px; 
    margin-top: 2px; 
  }
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}
.pratilipi {
  width: 100px;
  margin: 0 12px;
  background: #fff;
  position: relative;
  border-radius: 3px;
  @media screen and (max-width: 768px) {
    width: 88px;
    margin: 6px 6px 10px;
    margin: 0 12px;
  }
  .rating-tag {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    padding: 0 20px 3px;
    background-image: linear-gradient(0deg, #4e2d63, #b74772);
    border-radius: 8px;
    position: absolute;
    top: 8px;
    right: -32px;
    z-index: 1;
    height: 20px;
    width: 48px;
    @media screen and (max-width: 768px) {
      right: -5px;
      font-size: 10px;
      height: 16px;
      width: 32px;
    }
    img {
      height: 16px;
      width: 52px;
      line-height: 46px;
      padding: 0 5px;
      color: #fff;
      font-size: 24px;
      display: block;
      vertical-align: middle;
      @media screen and (max-width: 768px) {
        height: 12px;
        width: 44px;
      }
    }
    .rating-star-img {
      //padding-top: 10px;
      margin-bottom: 2px;
      //margin-left: 13px;
    }
    span {
      content: "";
      position: absolute;
      right: 10px;
      //bottom: 3px;
      z-index: 9;
    }
  }
  a.image-wrapper {
    position: relative;
    display: block;
    color: #fff;
    .pratilipi-image {
      margin: 0;
      height: 140px;
      width: 140px;
      border-radius: 8px;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
      @media screen and (max-width: 768px) {
        height: 100px;
        width: 100px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom: 1px solid #e9e9e9;
      }
    }
  }  
  .pratilipi-details {
    text-align: left;
    position: relative;
    span {
      display: block;
      color: #676767;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.title {
        font-size: 12px;
        font-weight: bold;
        line-height: 18px;
        max-height: 47px;
        margin: 10px 0 2px;
        color: #555;
      }
      &.author {
        font-size: 11px;
      }
    }
  }
}
</style>

<template>
  <div
    class="container"
    style="background-image: linear-gradient(to bottom, #313c33, #161c17);"
  >
    <b-container>
      <b-carousel
        controls
        indicators
        img-width="1024"
        img-height="480"
        :interval="3000"
      >
        <div
          v-for="(each_banner, index) in banners"
          :key="index"
          class="banners"
        >
          <router-link
            :to="{
              name: 'home-series',
              params: {
                slug: each_banner.attributes.actionUrl.split(
                  'comic-series/'
                )[1],
              },
            }"
            @click.native="trigerclick(each_banner)"
          >
            <b-carousel-slide
              class="image image-inside"
              :img-src="
                getHighResolutionImage(
                  each_banner.attributes.imageUrl +
                    '?width=240&enforceAspectRatio=true'
                )
              "
            ></b-carousel-slide>
          </router-link>
        </div>
      </b-carousel>
    </b-container>
  </div>
</template>

<script>
import mixins from "@/mixins";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [mixins],
  props: {
    banners: {
      type: Array,
      required: true,
    },
    "in-viewport-once": {
      default: true,
    },
  },
  created() {},
  methods: {
    ...mapActions("seriespage", [
      "fetchSeriesDetails",
      "fetchSeriesChapters",
      "fetchMoreSeriesChapters",
      "addSeriesToLibrary",
      "removeSeriesFromLibrary",
      "fetchSeriesDetailsBySlug",
      "resetShowPratilipiRemoveError",
    ]),

    trigerclick(data) {
      let slug = data.attributes.actionUrl.split(
        "https://pratilipicomics.com/comic-series/"
      )[1];
      //this.fetchSeriesDetailsBySlug(slug);
      this.triggerClickBannerEvent(data);
    },

    triggerClickBannerEvent(data) {
      this.triggerAnanlyticsEvent(`CLICK_BANNER`, "CONTROL", {
        LANGUAGE:"HINDI",
        SCREEN_NAME:"HomePage",
        SERIES_NAME: data.attributes.title,
        SERIES_ID: data.attributes.seriesId?data.attributes.seriesId:data.attributes.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-control-next-icon,
.carousel-control-prev-icon {
  @media only screen and (max-width: 640px) {
    display: none;
  }
}
.container {
  height: auto;
  width: 65%;
  @media only screen and (max-width: 640px) {
    height: auto;
    width: 65%;
    margin-bottom: 20px;
  }
}
.image {
  height: auto;
  width: 100%;
  object-fit: fill;
  @media only screen and (max-width: 640px) {
    object-fit: fill;
    height: auto;
    width: 100%;
    border-radius: 3%;
  }
}
</style>

<style lang="scss"></style>

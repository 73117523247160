<template>
  <div class="image">
    <img
      :data-lazy="
        getHighResolutionImage(
          this.coverImageUrl + '&width=150&enforceAspectRatio=true'
        )
      "
      :alt="displayTitle"
    />
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "PratilipiImage",
  mixins: [mixins],
  props: {
    coverImageUrl: {
      type: String,
      required: true,
    },
    displayTitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  margin: 5px;
  
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  border-radius: 4px;
 @media screen and (min-width: 768px) {
  height: 148px;
  width: 148px;
}
  @media screen and (max-width: 768px) {
    height: 100px;
    width: 100px;
  }
  img {
    @media screen and (min-width: 768px) {
    width: 148px;
    height: 148px;
  }
  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
  }
}
</style>
